import React from "react"

export default function DaZeroAlBrand() {
  return (
    <section className="section-dazeroalbrand">
      <div className="container">
        <div className="columns is-centered px-5">
          <div className="column is-four-fifths">
            <h1>DA ZERO<br />AL BRAND</h1>
            <h3>Guida completa al marketing strategico,<br/>dal posizionamento alla comunicazione.</h3>
            <p>
              Gli errori pi&ugrave; grandi che ostacolano il successo delle organizzazioni sono dovuti alla mancata
              comprensione dello scopo e dell’ordine cronologico in cui dovrebbero avvenire i diversi passaggi del
              posizionamento strategico.
            </p>
            <p>Questo libro, per la prima volta, presenta una mappa completa e inedita degli strumenti di marketing,
              sviluppata dagli autori in oltre 15 anni di esperienza, per guidare le aziende lungo un percorso strutturato
              dall'idea di business fino alla creazione del brand. Racconta come nascono i concept creativi in rapporto
              alle scelte di mercato, al contesto che cambia e al variare dei fattori economici e finanziari dell’organizzazione.
              Mostra come mantenere una coerenza tra il prodotto o servizio offerto,
              il modo in cui lo si comunica e i valori dell’azienda e del pubblico di riferimento.
            </p>
            <p>La guida definitiva e completa per
              lanciare un progetto vincente da zero, cominciando subito a ragionare nella giusta direzione.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
